import React from 'react';
import { Link } from "react-router-dom";
import faceBook from '../images/facebook.png';
import insta from '../images/instagram.png';
import linkedIn from '../images/linkedin-logo.png';
import logo from '../images/logo.png';

function Footer() {


  const openLinkedIn = () => {
        window.open('http://linkedin.com/company/labridge');
  }

  const openInsta = () => {
        window.open('https://www.instagram.com/labridgehealthtech/');
  }

  const openFaceBook = () => {
        window.open('https://www.facebook.com/profile.php?id=100092640300246')
  }
  return (
    <footer style={{backgroundColor:`#F7F7F7`}}>
     <div className='container '> 
        <div className='row' style={{paddingTop: '20px'}}>
                <div className='col-md-4' style={{alignItems: 'left',display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
                        <a className="navbar-brand" href="#"><img width={350} src={logo} alt=''/></a>
                        <p style={{color: '#4BB3EB', fontWeight: '600', fontSize: '16px'}}>“India's tursted name in Diagnostic Excellence”</p>
                </div>
                <div className='col-md-8 row'>
                <div className='col'>
                        <p className='p-0 m-0 mobile-text' style={{fontWeight:"700"}}>About Labridge</p>
                      <Link to="/who-we-are"> <p className='p-0 m-0 mobile-text' >Who we are</p></Link> 
                      <Link to="/ContactUs">  <p className='p-0 m-0 mobile-text' >Contact us</p></Link>
                </div>
                <div className='col'>
                        <p className='p-0 m-0 mobile-text' style={{fontWeight:"700"}}>For Partners</p>
                        <a href='https://partner.labridge.in/add-partner'> <p className='p-0 m-0 mobile-text' >Partners With Us</p></a>
                </div>
                <div className='col'>
                        <p className='p-0 m-0 mobile-text' style={{fontWeight:"700"}}>Learn More</p>
                        <Link to="/policy">   <p className='p-0 m-0 mobile-text' >Privacy and Policy</p></Link>
                        <Link to="/disclaimer">   <p className='p-0 m-0 mobile-text' >Disclaimer</p></Link>
                        <Link to="/refund-policy">   <p className='p-0 m-0 mobile-text' >Refund Policy</p></Link>
                        <Link to="/cancellation-policy">   <p className='p-0 m-0 mobile-text' >Cancellation Policy</p></Link>
                        <Link to="/terms-and-condition">   <p className='p-0 m-0 mobile-text' >Terms and condition</p></Link>
                </div>
                {/* <div>
                        <p className='p-0 m-0 mobile-text' style={{fontWeight:"700"}}>Social Media</p>
                        <p className='p-0 m-0 mobile-text' > </p>
                </div> */}
                </div>
        </div>
        <div>
                <hr></hr>
                <div className='d-flex' style={{justifyContent: 'space-between'}}>
                        <div>
                                <p>Copyright © 2024 Labridge All Rights Reserved</p>
                        </div>
                        <div>
                                <img src={linkedIn} style={{width: '20px', height: '20px', marginRight: '15px'}}  alt='' onClick={openLinkedIn}/>
                                <img src={insta} style={{width: '20px', height: '20px', marginRight: '15px'}}  alt='' onClick={openInsta}/>
                                <img src={faceBook} style={{width: '20px', height: '20px'}}  alt='' onClick={openFaceBook}/>
                        </div>
                </div>
        </div>
        {/* <div className='d-flex mt-3'>
                <img src={playstore} />
                <img src={applestore}/>
            </div> */}
        </div>
    </footer>
  )
}

export default Footer